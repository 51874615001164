<template>
  <a-drawer
    width="1000"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true"
  >
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <!-- <div style="margin-bottom:20px;">
      <a-radio-group
        v-model="type"
        button-style="solid"
      >
        <a-radio-button :value="1">设备信息</a-radio-button>
        <a-radio-button
          :value="2"
          v-if="form.agentId"
        >代理商信息</a-radio-button>
        <a-radio-button
          :value="3"
          v-if="form.userId && form.userId !== '0'"
        >商家信息</a-radio-button>
      </a-radio-group>
    </div> -->
    <a-descriptions
      bordered
      :column="3"
      size="middle"
    >
      <a-descriptions-item label="设备名称">{{ form.devName }}</a-descriptions-item>
      <a-descriptions-item label="设备序列号">{{ form.devCode }}</a-descriptions-item>
      <a-descriptions-item label="环境温度">{{ form.ambientTemperature }}</a-descriptions-item>
      <a-descriptions-item label="警示灯闪烁模式">{{ form.cautionLightModel }}</a-descriptions-item>
      <a-descriptions-item label="警示颜色">
        {{ form.cautionColour == 0 ? '红色' : form.cautionColour == 1 ? '绿色' : form.cautionColour == 2 ? '黄色' : '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="电池电压">{{ form.cellVoltage }}</a-descriptions-item>
      <a-descriptions-item label="充电电流">{{ form.chargeCurrent }}</a-descriptions-item>
      <a-descriptions-item label="放电电流">{{ form.dischargeCurrent }}</a-descriptions-item>
      <a-descriptions-item label="总发电量">{{ form.electricQuantityAll }}</a-descriptions-item>
      <!-- <a-descriptions-item label="IMSI">{{ form.imsi }}</a-descriptions-item> -->
      <a-descriptions-item label="当日发电量">{{ form.electricQuantityNowDay }}</a-descriptions-item>
      <a-descriptions-item label="未超速显示文字">{{ form.notSpeedText }}</a-descriptions-item>
      <a-descriptions-item label="未超速显示文字颜色">{{ form.notSpeedTextColour }}</a-descriptions-item>
      <a-descriptions-item label="未超速显示文字模式">
        {{ form.notSpeedTextModel == 0 ? '静止' : form.notSpeedTextModel == 1 ? '左移' : form.notSpeedTextModel == 2 ? '右移' :
          form.notSpeedTextModel == 3 ? '上移' : form.notSpeedTextModel == 4 ? '下移' : form.notSpeedTextModel == 5 ? '闪烁' :
            '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="未超速显示文字停留时间">{{ form.notSpeedTextWaitTime }}</a-descriptions-item>
      <a-descriptions-item label="来车方向">{{ form.nowCarDirection }}</a-descriptions-item>
      <a-descriptions-item label="来车数量">{{ form.nowCarNum }}</a-descriptions-item>
      <a-descriptions-item label="过车速度">{{ form.nowCarSpeed }}</a-descriptions-item>
      <a-descriptions-item label="过车时间">{{ form.nowCarTime }}</a-descriptions-item>
      <a-descriptions-item label="光伏状态 "> <a-tag
          color="green"
          v-if="form.photoVoltaicStatus == 0"
        >白天</a-tag>
        <a-tag
          color="red"
          v-else-if="form.photoVoltaicStatus == 1"
        >夜晚</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="负载模式">
        <a-tag
          color="green"
          v-if="form.loadStatus == 0"
        >开</a-tag>
        <a-tag
          color="red"
          v-else-if="form.loadStatus == 1"
        >关</a-tag>
        <span v-else>--</span>
      </a-descriptions-item>
      <a-descriptions-item label="雷达测试距离">{{ form.radarDistance }}m</a-descriptions-item>
      <a-descriptions-item label="本地限速值">{{ form.rateLimiting }}</a-descriptions-item>
      <a-descriptions-item label="太阳能工作模式">{{ form.solarEnergyModel == 1 ? '节能' : '正常' }}</a-descriptions-item>
      <a-descriptions-item label="待机显示文字">{{ form.standbyText }}</a-descriptions-item>
      <a-descriptions-item label="待机显示文字颜色">{{ form.standbyTextColour }}</a-descriptions-item>
      <a-descriptions-item label="待机显示文字颜色">{{ form.standbyTextColour }}</a-descriptions-item>
      <a-descriptions-item label="待机显示文字模式">
        {{ form.standbyTextModel == 0 ? '静止' : form.standbyTextModel == 1 ? '左移' : form.standbyTextModel == 2 ? '右移' :
          form.standbyTextModel == 3 ? '上移' : form.standbyTextModel == 4 ? '下移' : form.standbyTextModel == 5 ? '闪烁' :
            '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="待机显示文字停留时间">{{ form.standbyTextWaitTime }}</a-descriptions-item>
      <a-descriptions-item label="超速显示文字">{{ form.thenSpeedText }}</a-descriptions-item>
      <a-descriptions-item label="超速显示文字颜色">{{ form.thenSpeedTextColour }}</a-descriptions-item>
      <a-descriptions-item label="超速显示文字">{{ form.thenSpeedText }}</a-descriptions-item>
      <a-descriptions-item label="超速显示文字模式">
        {{ form.thenSpeedTextModel == 0 ? '静止' : form.thenSpeedTextModel == 1 ? '左移' : form.thenSpeedTextModel == 2 ? '右移'
          :
          form.thenSpeedTextModel == 3 ? '上移' : form.thenSpeedTextModel == 4 ? '下移' : form.thenSpeedTextModel == 5 ? '闪烁' :
            '--' }}
      </a-descriptions-item>
      <a-descriptions-item label="语音白天音量">{{ form.voiceVolumeDayTime }}</a-descriptions-item>
      <a-descriptions-item label="语音夜晚音量">{{ form.voiceVolumeNight }}</a-descriptions-item>
      <a-descriptions-item label="是否超速">{{ form.warningText ? '超速' : '未超速' }}</a-descriptions-item>
      <a-descriptions-item label="天气信息">{{ form.weatherText }}</a-descriptions-item>
      <a-descriptions-item label="工作时段">{{ form.workingHours }}</a-descriptions-item>
      <a-descriptions-item label="备注">{{ form.remark }}</a-descriptions-item>
    </a-descriptions>
  </a-drawer>
</template>

<script>

import { getVehicle } from '@/api/project/vehicle'
// import { getUser } from '@/api/user/agent'
// import { getUsers } from '@/api/user/enterprise'
export default {
  name: 'CreateForm',
  data () {
    return {
      submitLoading: false,
      form: {},
      type: 1,
      open: false,
      agentForm: {},
      userForm: {}
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 点击tab
    callback (key) {

    },
    handleUpdate (id) {
      getVehicle({ id }).then(response => {
        this.form = response.data
        console.log(this.form, '但是单独多')
        this.open = true
        // this.$nextTick(() => {
        //   this.$refs.map.mapInit(response.data.log, response.data.lat)
        // })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}
</style>
