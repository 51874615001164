import request from '@/utils/request'
// 查询来车记录分页
export function pageVehicle (query) {
  return request({
    url: '/gx/gx-device-record/page',
    method: 'get',
    params: query
  })
}
// 查询来车记录详细
export function getVehicle (data) {
  return request({
    url: '/gx/gx-device-record/detail',
    method: 'get',
    params: data
  })
}
// 删除来车记录
export function delVehicle (data) {
  return request({
    url: '/gx/gx-device-record/delete',
    method: 'post',
    data: data
  })
}
